<template>
  <div class="input-tech">
    <div class="input-wrapper" @click="focusInput">
      <div
        ref="inputContainer"
        class="input-container"
        contenteditable="true"
        @input="onInput"
        @keydown="onKeyDown"
      ></div>
    </div>
    <ul v-if="showSuggestions && filteredSuggestions.length > 0" class="suggestions">
      <li
        v-for="suggestion in filteredSuggestions"
        :key="suggestion"
        :class="{ active: suggestion === activeSuggestion }"
        @click="selectSuggestion(suggestion)"
        @mouseenter="activeSuggestion = suggestion"
        @mouseleave="activeSuggestion = ''"
      >
        {{ suggestion === '직접 입력' ? `${suggestion}: ${inputText}` : suggestion }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'InputTech',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  watch: {
    value(n, o) {
      if(o.length === 0) {
        this.initializeTags();
      }
    }
  },
  data() {
    return {
      inputContent: '',
      inputText: '',
      suggestions: [],
      showSuggestions: false,
      activeSuggestion: '',
      selectedTags: []
    }
  },
  computed: {
    filteredSuggestions() {
      const filtered = this.suggestions.filter(suggestion =>
        suggestion.toLowerCase().includes(this.inputText.toLowerCase()) &&
        !this.selectedTags.includes(suggestion)
      );
      filtered.push('직접 입력');
      return filtered;
    }
  },
  mounted() {
    this.initSuggestions();
    this.initializeTags();
  },
  methods: {
    initSuggestions() {
      this.$axios.get('public/product/tech').then(res => {
        this.suggestions = res.data.detail;
      });
    },
    initializeTags() {
      this.value.forEach(tag => {
        if (!this.selectedTags.includes(tag)) {
          this.selectedTags.push(tag);
          this.insertTag(tag);
        }
      });
    },
    onInput() {
      this.updateInputContent();
      this.inputText = this.getInputText();
      this.showSuggestions = this.inputText.length > 0;
    },
    clickSuggestion() {
      this.selectSuggestion(this.activeSuggestion);
    },
    onKeyDown(event) {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        event.preventDefault();
        this.navigateSuggestions(event.key === 'ArrowDown' ? 1 : -1);
      } else if (event.key === 'Enter') {
        event.preventDefault();
        if (this.activeSuggestion) {
          this.selectSuggestion(this.activeSuggestion);
        } else {
          const inputText = this.inputText.trim();
          if (inputText) {
            this.selectSuggestion('직접 입력');
          }
        }
      } else if (event.key === 'Backspace') {
        this.handleBackspace(event);
      }
    },
    navigateSuggestions(step) {
      const suggestions = this.filteredSuggestions;
      const currentIndex = suggestions.indexOf(this.activeSuggestion);
      let nextIndex = currentIndex + step;
      if (nextIndex < -1) nextIndex = suggestions.length - 1;
      if (nextIndex >= suggestions.length) nextIndex = -1;
      this.activeSuggestion = nextIndex === -1 ? '' : suggestions[nextIndex];
    },
    selectSuggestion(suggestion) {
      if (suggestion === '직접 입력') {
        suggestion = this.inputText.trim();
      }
      if (suggestion && !this.selectedTags.includes(suggestion)) {
        this.selectedTags.push(suggestion);
        this.$emit('update:value', this.selectedTags);
        this.clearInputText();
        this.insertTag(suggestion);
        this.$refs.inputContainer.focus();
        this.showSuggestions = false;
        this.inputText = '';
        this.$nextTick(() => {
          this.onInput();
        });
      }
    },
    insertTag(tag) {
      const tagElement = document.createElement('span');
      tagElement.className = 'tag';
      tagElement.textContent = tag;
      tagElement.contentEditable = false;
      
      const removeButton = document.createElement('i');
      removeButton.className = 'material-icons';
      removeButton.textContent = 'close';
      removeButton.onclick = (event) => {
        event.stopPropagation();
        tagElement.remove();
        console.log(tag);
        this.selectedTags = this.selectedTags.filter(t => t !== tag);
        this.$emit('update:value', this.selectedTags);
        this.$nextTick(() => {
          this.onInput();
        });
      };
      
      tagElement.appendChild(removeButton);
      
      const inputContainer = this.$refs.inputContainer;
      
      // 태그를 입력 컨테이너의 마지막 자식으로 추가
      inputContainer.appendChild(tagElement);
      
      // 새로운 텍스트 노드 추가
      const textNode = document.createTextNode('\u00A0');
      inputContainer.appendChild(textNode);
      
      // 커서를 새로운 텍스트 노드 뒤로 이동
      const selection = window.getSelection();
      const range = document.createRange();
      range.setStartAfter(textNode);
      range.setEndAfter(textNode);
      selection.removeAllRanges();
      selection.addRange(range);
    },
    clearInputText() {
      const inputContainer = this.$refs.inputContainer;
      while (inputContainer.lastChild && inputContainer.lastChild.nodeType === Node.TEXT_NODE) {
        inputContainer.removeChild(inputContainer.lastChild);
      }
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(inputContainer);
      range.collapse(false);
      selection.removeAllRanges();
      selection.addRange(range);
    },
    updateInputContent() {
      this.inputContent = this.$refs.inputContainer.innerText;
      const currentTags = Array.from(this.$refs.inputContainer.querySelectorAll('.tag')).map(tag => tag.textContent.slice(0, -5));
      
      // selectedTags와 currentTags를 동기화
      this.selectedTags = this.selectedTags.filter(tag => currentTags.includes(tag));
      currentTags.forEach(tag => {
        if (!this.selectedTags.includes(tag)) {
          this.selectedTags.push(tag);
        }
      });
    },
    getInputText() {
      const inputContainer = this.$refs.inputContainer;
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      
      // 마지막 태그 요소 찾기
      let lastTag = null;
      for (let i = inputContainer.childNodes.length - 1; i >= 0; i--) {
        if (inputContainer.childNodes[i].nodeType === Node.ELEMENT_NODE && inputContainer.childNodes[i].classList.contains('tag')) {
          lastTag = inputContainer.childNodes[i];
          break;
        }
      }
      
      let startNode;
      let startOffset;
      
      if (lastTag) {
        startNode = lastTag.nextSibling;
        startOffset = 0;
      } else {
        startNode = inputContainer.firstChild;
        startOffset = 0;
      }
      
      if (startNode) {
        const tempRange = document.createRange();
        tempRange.setStart(startNode, startOffset);
        tempRange.setEnd(range.endContainer, range.endOffset);
        return tempRange.toString().trim();
      }
      
      return '';
    },
    focusInput() {
      this.$refs.inputContainer.focus();
    },
    handleBackspace(event) {
      const inputContainer = this.$refs.inputContainer;
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);

      // 선택된 텍스트가 있는지 확인
      if (!selection.isCollapsed) {
        return; // 선택된 텍스트가 있으면 기본 동작 수행
      }

      // 커서 위치 확인
      const cursorPosition = this.getCursorPosition(inputContainer);

      if (cursorPosition === 0) {
        // 커서가 입력 영역의 시작 부분에 있을 때
        const firstChild = inputContainer.firstChild;
        if (firstChild && firstChild.nodeType === Node.ELEMENT_NODE && firstChild.classList.contains('tag')) {
          event.preventDefault();
          this.removeTag(firstChild);
        }
      } else {
        // 커서가 다른 위치에 있을 때
        let currentNode = range.startContainer;
        let offset = range.startOffset;

        // 현재 노드가 텍스트 노드이고 커서가 시작 부분에 있는 경우
        if (currentNode.nodeType === Node.TEXT_NODE && offset === 0) {
          currentNode = currentNode.previousSibling;
        } else if (currentNode === inputContainer && offset > 0) {
          // 현재 노드가 inputContainer이고 offset이 0보다 큰 경우
          currentNode = inputContainer.childNodes[offset - 1];
        }

        if (currentNode && currentNode.nodeType === Node.ELEMENT_NODE && currentNode.classList.contains('tag')) {
          event.preventDefault();
          this.removeTag(currentNode);
        }
      }
    },

    getCursorPosition(element) {
      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const preCaretRange = range.cloneRange();
      preCaretRange.selectNodeContents(element);
      preCaretRange.setEnd(range.endContainer, range.endOffset);
      return preCaretRange.toString().length;
    },

    removeTag(tagElement) {
      const tag = tagElement.textContent.slice(0, -1);
      tagElement.remove();
      this.selectedTags = this.selectedTags.filter(t => t !== tag);
      this.$nextTick(() => {
        this.onInput();
        this.focusAtEnd();
      });
    },

    focusAtEnd() {
      const inputContainer = this.$refs.inputContainer;
      const range = document.createRange();
      range.selectNodeContents(inputContainer);
      range.collapse(false);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  }
}
</script>

<style lang="stylus" scoped>
.input-tech
  position relative

.input-wrapper
  border 1px solid #e3e3e3
  border-radius 8px
  padding 12px 16px

.input-container
  min-height 20px
  outline none

.suggestions
  position absolute
  top 100%
  left 0
  right 0
  background-color white
  border 1px solid #ccc
  list-style-type none
  padding 0
  margin 0
  max-height 200px
  overflow-y auto
  z-index 1000

.suggestions li
  padding 5px 10px
  cursor pointer

.suggestions li:hover
.suggestions li.active
  background-color #f5f5f5

.suggestions li:last-child
  border-top 1px solid #ccc
</style>

<style lang="stylus">
.input-tech
  .tag
    background-color #f5f5f5
    margin 0 2px
    padding 0 10px
    height 28px
    line-height 28px
    border-radius 50px
    white-space nowrap
    user-select none
    color #333
    display inline-flex
    align-items center
    gap 4px
    width fit-content
    i
      font-size 15px
      cursor pointer

</style>